@layer base {
  html {
    -webkit-tap-highlight-color: transparent;
    background: #f9fdff;
  }
  p {
    @apply mb-6;
  }
  // .page-content {
  //   @apply overflow-x-hidden;
  // }
}

@layer components {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    @apply text-[#202124];
  }

  .entry-content h3 {
    @apply mb-5 text-lg font-semibold;
  }

  .entry-content ul {
    @apply mb-6 px-4 lg:mb-[50px];
  }

  .entry-content ul li {
    @apply mb-2 list-disc leading-9;
  }

  .entry-content p {
    @apply mb-6 lg:mb-[50px];
  }
  ._2p3a {
    width: 100% !important;
  }
  // bbc-accordion
  @mixin spacing-base($mb) {
    ol {
      &:last-child {
        margin-bottom: $mb;
      }
      @apply mb-4 pl-5;

      li {
        @apply list-decimal;
        ul {
          li {
            @apply list-decimal;
          }
        }
      }
    }
    ul {
      &:last-child {
        margin-bottom: $mb;
      }
      @apply mb-4 pl-5;

      li {
        &::marker {
          font-size: 12px;
        }
        @apply list-disc;

        ul {
          li {
            &::marker {
              font-size: 12px;
            }
            @apply list-circle;
          }
        }
      }
    }
    a {
      @apply text-blue-500 hover:text-blue-700;
    }
  }
  // bbc-accordion
  .gleads-accordion {
    .gleads-accordion-panel {
      @include spacing-base(16px);
    }
  }
  .invalid-textfield {
    @apply transition-colors duration-200 invalid:border-pink-500
           invalid:text-pink-600 focus:bg-white
           focus:outline-hidden focus:ring-0
           focus:invalid:border-pink-500 focus:invalid:ring-pink-500;
  }
  .section-white {
    @apply bg-white py-10 lg:py-20;
  }
  .section-blue {
    @apply py-10 lg:py-15;
  }
  .section-blue-post {
    @apply overflow-hidden py-10 lg:pt-15 lg:pb-5;
  }
}
