@tailwind base;
@tailwind components;
@tailwind utilities;

// Custom Styles
@import "custom/_styles";

// Custom Variables

html {
  font-family: "Helvetica", "Arial", sans-serif;
}
.zalo-chat-widget{
 
  bottom: 92px !important;
  right: 28px !important;
  @media (max-width: 768px) {
    bottom: 65px !important;
    right: 20px!important;
  }
}